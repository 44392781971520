import { Book, ShoppingCart } from "lucide-react";

export const BookCard = ({t, book, onPreview }: any) => (
  <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition-all duration-300 flex flex-col h-full">
    <div className="flex flex-col items-start mb-4 gap-2">
      <div className="flex w-full justify-center">
        <img
          src={book.preview}
          alt={`${t(book.title)} cover`}
          className="w-full object-cover rounded-md shadow-sm mr-4"
        />
      </div>

      <div className="flex-1">
        <h3 className="text-xl font-bold text-gray-800 mb-1 line-clamp-2">
          {t(book.title)}
        </h3>
        <p className="text-sm text-gray-600 mb-2">{book.author}</p>
        <p className="text-lg font-bold text-picton-blue-600">
          ${book.price.toFixed(2)}
        </p>
      </div>
    </div>
    <div className="mt-auto space-y-2">
      <button
        onClick={() => onPreview(book)}
        className="w-full bg-picton-blue-500 text-white px-4 py-2 rounded-md font-semibold hover:bg-picton-blue-600 transition-colors duration-300 flex items-center justify-center"
      >
        <Book className="w-5 h-5 mr-2" />
        {t('preview')}
      </button>
      <a
        className="w-full bg-green-500 text-white px-4 py-2 rounded-md font-semibold hover:bg-green-600 transition-colors duration-300 flex items-center justify-center"
        href={t(book.amazonUrl)}
        target="_blank"
        rel="noopener noreferrer"
        id={`${t(book.title)} amazon link`}
      >
        <ShoppingCart className="w-5 h-5 mr-2" />
        {t('buy')}
      </a>
    </div>
  </div>
);

export const BookPreview = ({t, book, onClose }: any) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 z-50 overflow-y-auto">
    <div className="bg-white p-8 rounded-2xl max-w-md w-full">
      <h2 className="text-xl md:text-3xl font-bold mb-1 md:mb-4 text-gray-800">{t(book.title)}</h2>
      <div className="flex justify-center">
        <img
          src={book.preview}
          alt={`${book.title} cover`}
          className="w-40 md:w-full object-cover rounded-md shadow-sm mb-1 md:mb-4"
        />
      </div>
      <p className="mb-2 md:mb-6 text-gray-700">{t(book.description)}</p>
      <p className="text-picton-blue-600 font-bold text-2xl mb-2 md:mb-6">
        ${book.price.toFixed(2)}
      </p>
      <div className="flex justify-end">
        <button
          onClick={onClose}
          className="bg-gray-200 text-gray-800 px-6 py-3 rounded-full font-semibold hover:bg-gray-300 transition-colors duration-300"
        >
          {t('close')}
        </button>
      </div>
    </div>
  </div>
);
