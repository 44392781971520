import { ChevronLeft, ChevronRight } from "lucide-react";
import { useState } from "react";

const BookCarousel = ({t, books }: any) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextBook = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % books.length);
  };

  const prevBook = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + books.length) % books.length
    );
  };

  return (
    <div className="relative bg-gradient-to-r from-picton-blue-500 to-blue-600 p-12 rounded-3xl shadow-xl">
      <div className="flex items-center justify-around">
        {books.length > 1 ? (
          <button
            onClick={prevBook}
            className="p-3 bg-white rounded-full shadow-md hover:bg-gray-100 transition-colors duration-300"
          >
            <ChevronLeft size={24} className="text-picton-blue-600" />
          </button>
        ) : null}
        <div className="text-center px-8">
          <h3 className="text-3xl font-bold mb-3 text-white">
            {t(books[currentIndex].title)}
          </h3>
          <p className="text-picton-blue-100 mb-3 text-xl">
            {books[currentIndex].author}
          </p>
          <p className="text-white font-bold text-2xl">
            ${books[currentIndex].price.toFixed(2)}
          </p>
        </div>
        {books.length > 1 ? (
          <button
            onClick={nextBook}
            className="p-3 bg-white rounded-full shadow-md hover:bg-gray-100 transition-colors duration-300"
          >
            <ChevronRight size={24} className="text-picton-blue-600" />
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default BookCarousel;
