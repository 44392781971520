import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BookCard, BookPreview } from "./book";
import BookCarousel from "./book-carousel";
import Header from "./header";

const books = [
  {
    id: 1,
    title: "fifty-cats.title",
    preview: "fiftycats.jpg",
    price: 9.99,
    amazonUrl: "fifty-cats.url",
    description: "fifty-cats.description",
  },
];

const BookSellerWebsite = () => {
  const [previewBook, setPreviewBook] = useState(null);
  const { t, i18n } = useTranslation();

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <Header t={t} changeLanguage={changeLanguage} />

      <div
        id="home"
        className="relative h-screen flex items-center justify-center overflow-hidden bg-gradient-to-r from-picton-blue-600 to-blue-700"
      >
        <img
          src="cover.webp"
          alt={t("heroImageAlt")}
          className="absolute inset-0 w-full h-full object-cover opacity-20"
        />
        <div className="relative z-10 text-center text-white px-4">
          <h2 className="text-6xl font-bold mb-6">{t("heroTitle")}</h2>
          <p className="text-2xl mb-12 max-w-2xl mx-auto">
            {t("heroDescription")}
          </p>
          <a
            href="#books"
            className="bg-white text-picton-blue-600 px-8 py-4 rounded-full text-xl font-semibold hover:bg-gray-100 transition-colors duration-300 transform hover:scale-105"
          >
            {t("exploreBooks")}
          </a>
        </div>
      </div>
      <main id="books" className="flex-grow container mx-auto px-4 py-16">
        <h2 className="text-4xl font-bold mb-12 text-center text-gray-800">
          {t("newBooks")}
        </h2>
        <BookCarousel t={t} books={books} />

        <h2 className="text-4xl font-bold mt-24 mb-12 text-center text-gray-800">
          {t("ourCollection")}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-12">
          {books.map((book) => (
            <BookCard
              key={book.id}
              t={t}
              book={book}
              onPreview={setPreviewBook}
            />
          ))}
        </div>
      </main>

      <footer className="bg-gray-800 text-white py-8 mt-12">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-between">
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h5 className="text-xl font-bold mb-4">{t("companyName")}</h5>
              <p className="text-gray-400">{t("footerDescription")}</p>
            </div>
          </div>
          <div className="border-t border-gray-700 mt-8 pt-8 text-center text-gray-400">
            <p>{t("copyright", { year: new Date().getFullYear() })}</p>
          </div>
        </div>
      </footer>

      {previewBook && (
        <BookPreview
          t={t}
          book={previewBook}
          onClose={() => setPreviewBook(null)}
        />
      )}
    </div>
  );
};

export default BookSellerWebsite;
