import { ChevronDown, Menu, X } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import i18n from "./i18n";

const Header = ({ t, changeLanguage }: any) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLangDropdownOpen, setIsLangDropdownOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(
    i18n.language.split("-")[0].toUpperCase()
  );
  const dropdownRef = useRef(null);

  const handleLanguageChange = (lang: string) => {
    changeLanguage(lang);
    setCurrentLanguage(lang.toUpperCase());
    setIsLangDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        dropdownRef.current &&
        !(dropdownRef.current as any).contains(event.target)
      ) {
        setIsLangDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <header className="bg-white text-gray-800 p-6 sticky top-0 z-40 shadow-md">
        <div className="container mx-auto flex justify-between items-center">
          <a href="#home">
            <img
              src="logo.png"
              alt={t("logoAlt")}
              className="h-[100px] object-cover"
            />
          </a>

          <nav className="hidden md:flex space-x-8">
            <a
              href="#home"
              className="text-xl font-semibold hover:text-picton-blue-600 transition-colors duration-300"
            >
              {t("home")}
            </a>
            <a
              href="#books"
              className="text-xl font-semibold hover:text-picton-blue-600 transition-colors duration-300"
            >
              {t("books")}
            </a>
          </nav>
          <div className="flex items-center space-x-4">
            <div className="relative" ref={dropdownRef}>
              <button
                onClick={() => setIsLangDropdownOpen(!isLangDropdownOpen)}
                className="flex items-center space-x-1 bg-gray-100 px-3 py-2 rounded-md hover:bg-gray-200 transition-colors duration-300"
              >
                <span>{currentLanguage}</span>
                <ChevronDown size={16} />
              </button>
              {isLangDropdownOpen && (
                <div className="absolute right-0 mt-2 w-32 bg-white rounded-md shadow-lg z-50">
                  <button
                    onClick={() => handleLanguageChange("en")}
                    className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                  >
                    English
                  </button>
                  <button
                    onClick={() => handleLanguageChange("it")}
                    className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                  >
                    Italiano
                  </button>
                </div>
              )}
            </div>
            <button
              className="md:hidden"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              {isMenuOpen ? <X size={32} /> : <Menu size={32} />}
            </button>
          </div>
        </div>
      </header>

      {isMenuOpen && (
        <div className="md:hidden bg-white text-gray-800 shadow-md">
          <nav className="flex flex-col items-center py-6">
            <a
              href="#home"
              className="font-semibold py-3 text-xl hover:text-picton-blue-600 transition-colors duration-300"
            >
              {t("home")}
            </a>
            <a
              href="#books"
              className="font-semibold py-3 text-xl hover:text-picton-blue-600 transition-colors duration-300"
            >
              {t("books")}
            </a>
          </nav>
        </div>
      )}
    </>
  );
};

export default Header;
